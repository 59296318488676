var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "manager-container" },
    [
      _vm._m(0),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 20 } },
            [
              _c("el-date-picker", {
                staticClass: "year-picker",
                attrs: {
                  format: "yyyy",
                  type: "year",
                  "value-format": "yyyy",
                  clearable: false,
                },
                model: {
                  value: _vm.year,
                  callback: function ($$v) {
                    _vm.year = $$v
                  },
                  expression: "year",
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "child-right-align", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "", plain: "", type: "primary" },
                  on: { click: _vm.showDialog },
                },
                [_vm._v("Manage")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-block" },
        [
          _c(
            "el-table",
            {
              staticClass: "el-table-block",
              attrs: {
                data: _vm.calendar,
                "row-key": "id",
                "default-expand-all": "",
              },
            },
            [
              _c("base-no-data", { attrs: { slot: "empty" }, slot: "empty" }),
              _c("el-table-column", {
                attrs: {
                  label: "Date",
                  prop: "calendarDate",
                  formatter: _vm.dateFormatter,
                  width: 120,
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Non-Working Day/Half Day",
                  prop: "fullDay",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            class: {
                              "full-day": scope.row.fullDay,
                              "half-day": !scope.row.fullDay,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.fullDay
                                    ? "Non-Working Day"
                                    : "Half Day"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Description",
                  prop: "description",
                  align: "center",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("add-date", {
        attrs: {
          title: _vm.title,
          visible: _vm.addDialogVisible,
          defaultYear: _vm.year,
          "calendar-list": _vm.calendar,
        },
        on: {
          "update:visible": function ($event) {
            _vm.addDialogVisible = $event
          },
          close: _vm.onClosed,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h1", { staticClass: "div-main-view-title" }, [
        _vm._v("Calendar Settings"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }