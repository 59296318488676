<template>
  <div class="manager-container">
    <div>
      <h1 class="div-main-view-title">Calendar Settings</h1>
    </div>
    <el-row>
      <el-col :span="20">
        <el-date-picker
          v-model="year"
          class="year-picker"
          format="yyyy"
          type="year"
          value-format="yyyy"
          :clearable="false"
        ></el-date-picker>
      </el-col>
      <el-col :span="4" class="child-right-align">
        <el-button round  plain type="primary" @click="showDialog">Manage</el-button>
      </el-col>
    </el-row>
    <div class="table-block">
      <el-table
        :data="calendar"
        row-key="id"
        default-expand-all
        class="el-table-block"
      >
        <base-no-data slot="empty"></base-no-data>
        <el-table-column
          label="Date"
          prop="calendarDate"
          :formatter="dateFormatter"
          :width="120"
          align="center">
        </el-table-column>
        <el-table-column
          label="Non-Working Day/Half Day"
          prop="fullDay"
          align="center"
        >
          <template slot-scope="scope">
            <span v-bind:class="{'full-day': scope.row.fullDay, 'half-day' : !scope.row.fullDay  }">
              {{ scope.row.fullDay ? 'Non-Working Day' : 'Half Day' }}
            </span>

          </template>
        </el-table-column>
        <el-table-column
          label="Description"
          prop="description"
          align="center"
        >
        </el-table-column>
      </el-table>
    </div>
    <add-date :title="title" :visible.sync="addDialogVisible" :defaultYear="year" :calendar-list="calendar" @close="onClosed" ></add-date>
  </div>
</template>

<script>
import moment from 'moment'
import AddDate from '@/views/systemManager/calendarManager/components/addDate'
import { formatDate } from '@/utils/dateTime.js'

export default {
  name: 'index',
  components: { AddDate },
  data () {
    const currentYear = moment().format('YYYY')
    return {
      year: currentYear,
      calendar: [],
      addDialogVisible: false,
      title: 'Manage Calendar'
    }
  },

  mounted () {
    this.getYearCalendar()
  },

  watch: {
    year (newState, oldState) {
      this.getYearCalendar()
    }
  },

  methods: {

    onClosed() {
      this.getYearCalendar()
    },

    showDialog(){
      this.addDialogVisible = true
    },

    getYearCalendar () {
      const url = `${this.$apis.getCalendarByYear}/${this.year}`
      this.$request.get({
        url
      }).then(res => {
        if (res.code === 1000) {
          if (res.data) {
            this.calendar = this.processDate(res.data);
          } else {
            this.calendar = []
          }
        }
      })
    },

    processDate(calendar) {

      Array.from(calendar).forEach(d => {
        d.createdAt = new Date(d.createdAt)
        d.updatedAt = new Date(d.updatedAt)
        d.calendarDate = new Date(d.calendarDate)
      });
      return calendar;
    },

    dateFormatter(row, column) {
      return formatDate(row.calendarDate);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "index";

.child-right-align {
  text-align: right;
}
.container {
  height: 100%;
  position: relative;
}
.full-day{
  color: #CC333F;
}
.half-day{
  color: #0F3F6F;

}

</style>
